<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Company</h1>
          <v-form ref="investmentForm">
            <div class="imageBox">
              <div class="imageBox">
                <img
                  v-if="imageFileUrl"
                  :src="imageFileUrl"
                  alt=""
                  @click="onImagePick"
                />
                <v-label>Company Image</v-label>
                <v-file-input
                  class="mt-1"
                  v-if="imageFileUrl == undefined"
                  v-model="imageFile"
                  outlined
                  prepend-inner-icon="mdi-camera"
                  prepend-icon=""
                  @change="onChangeImage"
                  placeholder="Company Image"
                ></v-file-input>
              </div>
            </div>
            <v-label>Company Name</v-label>
            <v-text-field
              v-model="companyName"
              outlined
              placeholder="Company Name"
              class="mt-1"
            ></v-text-field>
            <v-label>Company Description</v-label>
            <v-textarea
              v-model="companyDescription"
              outlined
              class="mt-1"
              placeholder="Company Name"
            ></v-textarea>
            <v-btn @click="validate" large>Add</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "AddInvestmentCompany",
  data: () => ({
    companyName: "",
    companyDescription: "",
    imageFileUrl: undefined,
    imageFile: undefined,
  }),
  methods: {
    validate() {
      this.$refs.investmentForm.validate();
    },
    onChangeImage() {
      if (this.imageFile != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageFileUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageFile);
      } else {
        this.imageFileUrl = undefined;
      }
    },
    onImagePick() {
      this.imageFileUrl = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>